import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Button } from "@mui/material";
import "./profile.scss";
import {
  EditProfile,
  ManageAddresses,
  ManageAgents,
  ManagePayments,
  MyAuctionPost,
  MyBooking,
  MyListedPost,
  MyWishlist,
} from "../../features";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ManageReviews from "../../features/profile/ManageReviews";
import MyEarnings from "../../features/profile/MyEarnings";
import useAuth from "../../hooks/useAuth";
import { useLocation } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import MyBids from "../myBids";

const Profile = () => {
  const translation = useTranslation();
  const location = useLocation();
  const { state } = location;
  const user = useAuth();
  const [activeCase, setActiveCase] = useState<number>(0);
  const [newCls, setNewCls] = useState<boolean>(false);

  const data = [
    {
      id: 2,
      name: translation.profile.myListing,
      img: "/static/images/myListing.svg",
    },
    {
      id: 1,
      name: translation.header.bids,
      img: "/static/images/myListing.svg",
    },
    {
      id: 9,
      name: translation.profile.myAuctionPost,
      img: "/static/images/myListing.svg",
    },
    {
      id: 3,
      name: translation.profile.myWishlist,
      img: "/static/images/myListing.svg",
    },
    {
      id: 4,
      name: translation.profile.myEarning,
      img: "/static/images/myEarning.svg",
    },
    {
      id: 5,
      name: translation.profile.manageReview,
      img: "/static/images/review.svg",
    },
    {
      id: 6,
      name: translation.profile.managePayment,
      img: "/static/images/paymentmy.svg",
    },
    {
      id: 7,
      name: translation.profile.manageAddress,
      img: "/static/images/myaddress.svg",
    },
    {
      id: 8,
      name: translation.profile.manageAgent,
      img: "/static/images/myAgent.png",
    },
  ];

  const handleActiveCase = () => {
    switch (activeCase) {
      case 1:
        return <MyBids profile={true} />;
      case 2:
        return <MyListedPost />;
      case 3:
        return <MyWishlist />;
      case 4:
        return <MyEarnings />;
      case 5:
        return <ManageReviews />;
      case 6:
        return <ManagePayments />;
      case 7:
        return <ManageAddresses />;
      case 8:
        return <ManageAgents />;
      case 9:
        return <MyAuctionPost activeCase={activeCase} />;
      default:
        return <EditProfile />;
    }
  };

  useEffect(() => {
    if (state === "sellPost") {
      setActiveCase(2);
    } else if (state === "auctionPost") {
      setActiveCase(9);
    } else if (state === "wishlist") {
      setActiveCase(3);
    } else if (state === "earnings") {
      setActiveCase(4);
    } else if (state === "payment") {
      setActiveCase(6);
    }
  }, [state]);

  const handleSwitch = (id: number) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setActiveCase(id);
    setNewCls(true);
  };

  useEffect(() => {
    handleActiveCase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCase]);

  return (
    <Layout>
      <div className="wrapCls">
        <div className="comment_card">
          <div className="container">
            <div className="pMain">
              <div className="pLeft">
                <div
                  className="userDetail"
                  onClick={() => setActiveCase(0)}
                  style={{ cursor: "pointer" }}
                >
                  <figure>
                    <img
                      style={{
                        height: "70px",
                        width: "70px",
                        borderRadius: "100%",
                      }}
                      className={user?.image ? "img_style" : "img_style2"}
                      src={user?.image || "/static/images/userDiv.png"}
                      alt=""
                    />
                  </figure>
                  <div className="username">
                    <h3>{user?.userName || ""}</h3>
                    <h4>{user?.email || ""}</h4>
                  </div>
                </div>
                <ul className="profileSidebar">
                  {data?.map((item) => (
                    <li
                      key={item?.id}
                      className={activeCase === item?.id ? "active_tab" : ""}
                      onClick={() => {
                        setNewCls(true);
                        handleSwitch(item?.id);
                      }}
                    >
                      <figure>
                        <img src={item?.img} alt="" />
                      </figure>
                      <div className="flexDivarrow">
                        <h3>{item?.name}</h3>
                        <ArrowForwardIosIcon />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="pRyt">{handleActiveCase()}</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
