import React, { ChangeEvent, useEffect, useState } from "react";
import Layout from "../../layout";
import { CarNumberPlate, InputField } from "../../components";
// import "./addPost.scss";
import OTPInput from "react-otp-input";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { Button } from "../../components";
import FormLabel from "@mui/material/FormLabel";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import {
  isNumber,
  isString,
  letters,
  number,
  saudiCitiesList,
  saudiCitiesListAr,
} from "../../utils/validation";
import {
  useAddOfferPostMutation,
  useAddSellPostMutation,
  useLazyGetAllCateQuery,
  useLazyGetPostDetailByIdQuery,
  useLazyGetPostServiceQuery,
  useUpdateSellPostMutation,
} from "../../service/posts";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import { Category, ListedPost, PostService } from "../../types/General";
import { useNavigate, useParams } from "react-router-dom";
import { otpStyle } from "../../utils/AuthModalStyle";
import useTranslation from "../../hooks/Translation";

const AddOfferPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const translation = useTranslation();
  const language = getFromStorage(STORAGE_KEYS.language);

  const [allCategory] = useLazyGetAllCateQuery();
  const [postService] = useLazyGetPostServiceQuery();
  const [updateOfferPost, updateSellPostData] = useUpdateSellPostMutation();
  const [addOfferPost, { isLoading }] = useAddOfferPostMutation();
  const [getPostDetailById] = useLazyGetPostDetailByIdQuery();

  const [otp, setOtp] = useState("");

  const [category, setCategory] = useState("");
  const [address, setAddress] = useState("");
  const [inputs, setInputs] = useState<string[]>(["", "", ""]);

  const [priceType, setPricetype] = useState("Fixed");
  const [error, setError] = useState(false);
  const [voilation, setVoilation] = useState(false);
  const [category1, setCategory1] = useState<Category[]>([]);
  const [service, setService] = useState<PostService[]>([]);
  const [serviceId, setServiceId] = useState<string[]>([]);
  const [selectedId, setSelectedId] = useState("");
  const [listedPost, setListedPost] = useState<ListedPost | null>(null);
  // const validChars = /[ا-وىبحدرسصطعقلمنهـABDJRSTLXTEGKZNHUV]/;
  // const validChars = /[aAbBjJdDrRsSxXtTeEgGkKlLzZnNhHuUvV'ابحدرسصطعقكلمنهـوى]/;
  const validEnglishChars = /[aAbBjJdDrRsSxXtTeEgGkKlLzZnNhHuUvV']/;
  const validArabicChars = /[ابحدرسصطعقكلمنهـوى]/;

  const arabicToEnglishMap: { [key: string]: string } = {
    ا: "A",
    ب: "B",
    ح: "J",
    د: "D",
    ر: "R",
    س: "S",
    ص: "X",
    ط: "T",
    ع: "E",
    ق: "G",
    ك: "K",
    ل: "L",
    م: "Z",
    ن: "N",
    هـ: "H",
    و: "U",
    ى: "V",
  };

  const engToArabic: { [key: string]: string } = {
    a: "ا",
    b: "ب",
    c: "ث",
    d: "د",
    e: "ع",
    f: "و",
    g: "ق",
    h: "ح",
    i: "ي",
    j: "ح",
    k: "ك",
    l: "ل",
    m: "م",
    n: "ن",
    o: "ع",
    p: "ب",
    q: "ق",
    r: "ر",
    s: "ص",
    t: "ط",
    u: "و",
    v: "ى",
    w: "و",
    x: "ص",
    y: "ي",
    z: "م",
  };

  const handleChangePriceType = (event: any) => {
    setPricetype(event.target.value);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const handleChangeAddress = (event: SelectChangeEvent) => {
    setAddress(event.target.value as string);
  };

  // const handleChange = (
  //   index: number,
  //   event: ChangeEvent<HTMLInputElement>
  // ) => {
  //   let { value } = event.target;

  //   value = value.replace(/[^a-zA-Z]/g, "");

  //   const newInputs = [...inputs];
  //   newInputs[index] = value.toUpperCase(); // Convert to uppercase
  //   setInputs(newInputs);

  //   // Move focus to the next input if the current input is not empty and not the last input
  //   if (value !== "" && index < inputs.length - 1) {
  //     const nextInput = document.getElementById(
  //       `input-${index + 1}`
  //     ) as HTMLInputElement | null;
  //     if (nextInput) nextInput.focus();
  //   }
  // };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && inputs[index] === "") {
      // Move to the previous input if backspace is pressed on an empty input
      const prevInput = document.getElementById(`input-${index - 1}`);
      if (prevInput) {
        (prevInput as HTMLInputElement).focus();
      }
    }
  };

  const handleChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    let { value } = event.target;
    const validChars = language === "en" ? validEnglishChars : validArabicChars;

    // Only allow valid Arabic or English characters
    value = value
      .split("")
      .filter((char) => validChars.test(char))
      // .map((char) => arabicToEnglishMap[char] || char.toUpperCase())
      .map((char) => char.toUpperCase())
      .join("");

    const newInputs = [...inputs];
    newInputs[index] = value; // Already converted to uppercase and translated if necessary
    setInputs(newInputs);

    // Move focus to the next input if the current input is not empty and not the last input
    if (value && index < inputs.length - 1) {
      const nextInput = document.getElementById(
        `input-${index + 1}`
      ) as HTMLInputElement | null;
      if (nextInput) nextInput.disabled = false; // Enable next input
      if (nextInput) nextInput.focus();
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue("countryCode", country?.dialCode);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      meaningOfPlate: listedPost?.meaningOfPlate || "",
      phone: listedPost?.postPhone || "",
      countryCode: "+966",
      termsCondition: false,
      // voilationAmount: "",
      violationAmount: listedPost?.violationAmount || "",
    },
    validationSchema: Yup.object({
      termsCondition: Yup.boolean(),
      meaningOfPlate: Yup.string()
        .min(2, translation.validations.min_two)
        .max(80, translation.validations.max_Eighty),
      violationAmount: serviceId.some((id) => {
        const selectedService = service.find((item) => item._id === id);
        return selectedService?.type === 1;
      })
        ? Yup.string().required(translation.validations.required_field)
        : Yup.string().notRequired(),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      if (!category || !inputs?.length || !otp || !address) {
        return;
      }

      let body = {
        posttitle:
          language === "en"
            ? inputs.join("")
            : inputs.map((char: string) => arabicToEnglishMap[char])?.join(""),
        carPlatenumber: otp,
        category: category,
        postLocation: address,
        postPhone: formik.values.phone,
        postCountryCode: "+966",
        typeOfPrice: priceType,
        meaningOfPlate: formik.values.meaningOfPlate,
        role: "OFFER",
        serviceId: serviceId,
        ...(serviceId.some((id) => {
          const selectedService = service.find((item) => item._id === id);
          return selectedService?.type === 1;
        })
          ? { violationAmount: formik.values.violationAmount }
          : {}),
      };
      console.log(body, "body");

      if (id) {
        try {
          const response = await updateOfferPost({
            body,
            id,
          }).unwrap();
          if (response?.statusCode === 200) {
            successToast(translation.toast_messages.postUpdated);
            navigate("/profile", { state: "sellPost" });
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      } else {
        try {
          const response = await addOfferPost(body).unwrap();
          if (response?.statusCode === 200) {
            successToast(translation.toast_messages.postAdded);
            navigate("/profile", { state: "sellPost" });
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      }

      formik.setSubmitting(false);
    },
  });

  const getPlateDetailById = async (id: string) => {
    try {
      const response = await getPostDetailById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setListedPost(response?.data);
        setInputs(response?.data?.posttitle.split(""));
        setOtp(response?.data?.carPlatenumber || "");
        setCategory(response?.data?.categoryData?._id || "");
        setAddress(response?.data?.postLocation || "");
        setPricetype(response?.data?.typeOfPrice || "");
        setServiceId(response?.data?.serviceDetails?.map((item) => item?._id));
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getPlateDetailById(id);
    }
  }, []);

  const getAllCate = async () => {
    try {
      const response = await allCategory({}).unwrap();
      if (response?.statusCode === 200) {
        setCategory1(response?.data);
        setSelectedId(response?.data?.[0]?._id || "");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getPostService = async () => {
    try {
      const response = await postService({}).unwrap();
      if (response?.statusCode === 200) {
        setService(response?.data || []);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllCate();
    getPostService();
  }, []);

  return (
    <Layout>
      <Loader isLoad={isLoading || updateSellPostData?.isLoading} />
      <div className="wrapCls">
        <div className="comment_card">
          <div className="container">
            <div className="main_add">
              <h1 className="prdct_dtl_hdng">
                {id
                  ? translation.posts.offerPostUpdate
                  : translation.posts.offerPostAdd}
              </h1>
              <div className="plate_box">
                <ul className="pLft">
                  <li>
                    {otp
                      ? otp
                          ?.split("")
                          .reverse()
                          /* @ts-ignore */
                          ?.map((item: string) => number[`${item}`])
                          ?.join(" ")
                      : "XXXX"}
                  </li>

                  <li>
                    {language === "en"
                      ? inputs[0] === ""
                        ? "XXX"
                        : inputs
                            /* @ts-ignore */
                            ?.map(
                              (item: string) =>
                                /* @ts-ignore */
                                letters[`${item?.toLowerCase()}`]
                            )
                            ?.reverse()
                            .join(" ")
                      : inputs[0] === ""
                      ? "XXX"
                      : inputs
                          .map(
                            (char) => engToArabic[char.toLowerCase()] || char
                          )
                          .join(" ")}
                  </li>
                  <li>{otp ? otp.split("").join(" ") : "XXXX"}</li>
                  <li>
                    {language === "en"
                      ? inputs[0]?.length
                        ? inputs.join(" ")
                        : "XXX"
                      : inputs[0]?.length
                      ? inputs.join(" ")
                      : "XXX"}
                  </li>
                </ul>
                <div
                  className="pRyt"
                  style={{
                    background: category === selectedId ? "#3c82c8" : "",
                  }}
                >
                  <figure>
                    <img src="/static/images/lowhate_icon.png" alt="" />
                  </figure>
                  <p>السعودية</p>
                  <div className="ksa">
                    <h4>K</h4>
                    <h4>S</h4>
                    <h4>A</h4>
                  </div>
                  {category === selectedId ? (
                    <figure className="arrow">
                      <img src="/static/images/downArrow.svg" />
                    </figure>
                  ) : (
                    <div className="greenDot" />
                  )}
                </div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <div className="addOtp">
                    <p>{translation.posts.postTitle}</p>
                    <div className="plate_board">
                      {inputs.map((value, index) => (
                        <input
                          className="plate_no"
                          key={index}
                          id={`input-${index}`}
                          maxLength={1}
                          value={value}
                          onChange={(event) => handleChange(index, event)}
                          onKeyDown={(event) => handleKeyDown(index, event)}
                          disabled={!!id || (index > 0 && !inputs[index - 1])}
                        />
                      ))}
                    </div>
                    {error && !inputs?.[0]?.length ? (
                      <h6 className="err_msg">
                        {" "}
                        {translation.validations.required_field}
                      </h6>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="addOtp cr_nmbr">
                    <p>{translation.posts.carPlateNum}</p>
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      // renderInput={(props) => <input {...props} />}
                      inputStyle={otpStyle}
                      inputType="tel"
                      containerStyle={{ width: "100%", direction: "ltr" }}
                      renderInput={(props, index) => (
                        <input {...props} disabled={!!id} /> // Disable the OTP input field if ID is present
                      )}
                    />
                    {error && !otp ? (
                      <h6 className="err_msg">
                        {" "}
                        {translation.validations.required_field}
                      </h6>
                    ) : (
                      ""
                    )}
                    <div className="addOtp">
                      <p>{translation.posts.meaningOfPlate}</p>
                      <InputField
                        disabled={id ? true : false}
                        placeholder=""
                        name="meaningOfPlate"
                        id="meaningOfPlate"
                        inputProps={{
                          maxLength: 50,
                        }}
                        value={formik.values.meaningOfPlate}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isString(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.meaningOfPlate &&
                          formik.errors.meaningOfPlate
                        }
                      />
                      <p className="meaningCount">{`${formik.values.meaningOfPlate?.length}/50`}</p>
                    </div>
                    <div className="addOtp">
                      <p>{translation.home.category}</p>
                      <Select
                        fullWidth
                        value={category}
                        onChange={handleCategoryChange}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          {translation.Globals.select}
                        </MenuItem>

                        {category1?.length
                          ? category1?.map((item) => (
                              <MenuItem key={item?._id} value={item?._id}>
                                {language === "en"
                                  ? item?.categoryName || ""
                                  : item?.categoryName_ar || ""}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {error && !category ? (
                        <h6 className="err_msg">
                          {" "}
                          {translation.validations.required_field}
                        </h6>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="addOtp">
                      <p>{translation.Globals.phone_number}</p>
                      <PhoneInput
                        country={"in"}
                        value={formik.values.countryCode + formik.values.phone}
                        onChange={(phone: any, country: any) =>
                          handleChangePhone(phone, country)
                        }
                        onBlur={formik.handleBlur}
                        placeholder="0 (000) 000-000"
                        enableSearch={false}
                        inputStyle={{
                          width: "100%",
                          backgroundColor: "#EBEBEB",
                          textAlign: language === "ar" ? "right" : "left",
                          direction: language === "ar" ? "rtl" : "ltr",
                        }}
                        onlyCountries={["in", "sa"]}
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <h6 className="err_msg">
                          {formik.touched.phone && formik.errors.phone}
                        </h6>
                      ) : (
                        ""
                      )}

                      <div style={{ marginBottom: 10 }} />
                    </div>
                    <div className="addOtp">
                      <p>{translation.Globals.address}</p>
                      <Select
                        fullWidth
                        value={address}
                        onChange={handleChangeAddress}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          {translation.Globals.select}
                        </MenuItem>

                        {language === "en"
                          ? saudiCitiesList?.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))
                          : saudiCitiesListAr?.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                      </Select>
                      {error && !address ? (
                        <h6 className="err_msg">
                          {" "}
                          {translation.validations.required_field}
                        </h6>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <FormGroup className="chckbx_btnui ">
                    {service?.length
                      ? service?.map((item) => (
                          <>
                            <FormControlLabel
                              key={item?._id}
                              checked={serviceId?.includes(item?._id)}
                              control={<Checkbox />}
                              label={
                                language === "en"
                                  ? item?.serviceName || ""
                                  : item?.serviceName_ar || ""
                              }
                              onChange={(e) => {
                                if (serviceId.includes(item?._id)) {
                                  setServiceId((serviceId) =>
                                    serviceId.filter((s) => s !== item?._id)
                                  );
                                } else {
                                  setServiceId((serviceId) => [
                                    ...serviceId,
                                    item?._id,
                                  ]);
                                }
                              }}
                            />
                            {item?.type === 1 &&
                              serviceId?.includes(item?._id) && (
                                <div
                                  className="addOtp"
                                  style={{ marginTop: "20px" }}
                                >
                                  <InputField
                                    placeholder={
                                      translation.Globals.violationAmount
                                    }
                                    name="violationAmount"
                                    id="violationAmount"
                                    value={formik.values.violationAmount}
                                    onChange={(val) => {
                                      if (
                                        val.target.value === " " ||
                                        val.target.value === "."
                                      ) {
                                      } else if (isNumber(val.target.value)) {
                                        formik.handleChange(val);
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                    helperText={
                                      formik.touched.violationAmount &&
                                      formik.errors.violationAmount
                                    }
                                    inputProps={{
                                      maxLength: 10,
                                    }}
                                  />
                                </div>
                              )}
                            <br />
                          </>
                        ))
                      : null}
                  </FormGroup>

                  <FormGroup className="trmscndtn_btnui ">
                    <FormControlLabel
                      label=""
                      control={<Checkbox />}
                      checked={formik.values.termsCondition}
                      name="termsCondition"
                      onChange={formik.handleChange}
                    />
                    <p>
                      {translation.Globals.agree}{" "}
                      <span
                        className="trmns"
                        onClick={() => navigate("/terms-&-conditions")}
                      >
                        {translation.Globals.termsConditions}
                      </span>
                    </p>
                  </FormGroup>
                  <div className="disclaimer">
                    <p>{translation.posts.disclaimer}</p>
                  </div>
                  <div className="btn sbmt_btn">
                    <Button
                      value={translation.Globals.submit}
                      disabled={!formik.values.termsCondition}
                      onClick={() => setError(true)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddOfferPost;
