import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "./review.scss";
import { Button, MyBidCard } from "../../components";
import {
  useLazyGetMyReviewQuery,
  useLazyGlobalSearchQuery,
  useNotifyUserMutation,
} from "../../service/home";
import { Loader, errorToast, successToast } from "../../helpers";
import { ListedPost } from "../../types/General";
import { useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";
import useTranslation from "../../hooks/Translation";

const Review = () => {
  const translation = useTranslation();
  const user = useAuth();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state } = location;
  console.log(state, "statestate");

  const arabicToEnglishMap: { [key: string]: string } = {
    ا: "A",
    ب: "B",
    ح: "J",
    د: "D",
    ر: "R",
    س: "S",
    ص: "X",
    ط: "T",
    ع: "E",
    ق: "G",
    ك: "K",
    ل: "L",
    م: "Z",
    ن: "N",
    هـ: "H",
    و: "U",
    ى: "V",
  };

  const engToArabic: { [key: string]: string } = {
    a: "ا",
    b: "ب",
    c: "ث",
    d: "د",
    e: "ع",
    f: "و",
    g: "ق",
    h: "ح",
    i: "ي",
    j: "ح",
    k: "ك",
    l: "ل",
    m: "م",
    n: "ن",
    o: "ع",
    p: "ب",
    q: "ق",
    r: "ر",
    s: "ص",
    t: "ط",
    u: "و",
    v: "ى",
    w: "و",
    x: "ص",
    y: "ي",
    z: "م",
  };

  const [globalSearchMutation] = useLazyGlobalSearchQuery();
  const [notifyMutation] = useNotifyUserMutation();

  const [searchPost, setSearchPost] = useState<ListedPost[]>([]);

  const handleNotify = async () => {
    try {
      const response = await notifyMutation({
        carPlatenumber: state?.carPlatenumber?.join(""),
        posttitle: state?.posttitle.join(""),
      }).unwrap();
      if (response?.statusCode === 200) {
        successToast("we will notify you once number plate is available");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "Something went wrong");
    }
  };
  const getSearch = async (
    carPlatenumber: string[],
    posttitle: string[],
    selectedId: string
  ) => {
    try {
      const response = await globalSearchMutation({
        category: selectedId,
        userId: user?._id ? user?._id : "",
        posttitle: posttitle.join(""),
        carPlatenumber: carPlatenumber.join(""),
      }).unwrap();
      if (response?.statusCode === 200 && response?.data?.length) {
        setSearchPost(response?.data || []);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  // useEffect(() => {
  //   if (state) {
  //     getSearch(state?.carPlatenumber, state?.posttitle, state?.category);
  //   }
  // }, []);

  useEffect(() => {
    if (state) {
      const translatedPostTitle = state.posttitle.map(
        (char: string) => arabicToEnglishMap[char] || char
      );
      const carPlatenumber = state.carPlatenumber?.map((char: any) => char);

      getSearch(carPlatenumber, translatedPostTitle, state.category);
    }
  }, [state]);

  return (
    <Layout>
      <div className="wrapCls">
        <div className="review_card">
          <div className="container">
            <div className="number_plate">
              {state?.posttitle?.length
                ? state?.posttitle?.map((item: string, i: number) => (
                    <div className="number" key={i}>
                      <p>{item || ""}</p>
                    </div>
                  ))
                : null}
            </div>
            <p className="rsult">
              {searchPost?.length ? searchPost?.length : 0}{" "}
              {translation.home.result}
            </p>

            <div className="bids_div">
              {searchPost?.length ? (
                searchPost?.map((item) => (
                  <MyBidCard
                    category={item?.categoryData?.categoryName}
                    posttitle={item?.posttitle}
                    price={item?.price}
                    carPlatenumber={item?.carPlatenumber}
                    _id={item?._id}
                    state="review"
                    role={item?.role}
                  />
                ))
              ) : (
                <div className="noData" style={{ padding: "30px 0" }}>
                  <figure>
                    <img src="/static/images/noData.webp" alt="" />
                  </figure>
                  <h3> {translation.error.noData}</h3>
                  <div className="notifyBtn">
                    <Button
                      value={translation.home.notifyMe}
                      onClick={() => {
                        if (!user) {
                          dispatch(
                            toggleAuthModal({
                              isAuthModalVisible: true,
                              ModalType: "login",
                            })
                          );
                        } else {
                          handleNotify();
                        }
                      }}
                    />
                  </div>
                  <p>
                    {translation.home.notifyMsg} {state?.posttitle.join("")}{" "}
                    {state?.carPlatenumber} is
                    {translation.home.available}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Review;
